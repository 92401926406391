<template>
  <q-card flat class="productOfferInfo">
    <q-card-section>
      <div class="title text-weight-bold text-dark"
        ><SvgIcon size="1.5rem" icon="offer" class="q-mr-sm vm" />Offers</div
      >
      <q-separator class="bg-green-3" />
      <q-list dense class="q-pt-sm offerBlock">
        <q-item
          v-for="(discount, index) in discountsDetail?.discounts"
          :key="`${discount.discountID}-${index}`"
        >
          <q-item-section>
            <q-item-label class="text-body2 text-dark word-break-word"
              >{{ discount.displayName }}
            </q-item-label>
          </q-item-section>
          <q-item-section side
            ><q-btn
              unelevated
              no-caps
              flat
              color="green"
              label="See How"
              size="13px"
              padding="0"
              class="no-hover text-underline"
              :to="{
                name: 'DiscountDetail',
                params: { seoName: discount.discountID },
              }"
          /></q-item-section>
        </q-item>
      </q-list>
    </q-card-section>
  </q-card>
</template>

<script>
export default {
  name: 'ProductOfferInfo',
  props: {
    discountsDetail: {
      type: Object,
      default: () => {
        return new Object({})
      },
    },
  },
}
</script>

<style lang="scss">
.productOfferInfo {
  border: 1px solid rgba(33, 186, 63, 0.3);
  background: rgba(33, 186, 63, 0.1);
  -webkit-border-radius: 7px;
  border-radius: 7px;
  margin-bottom: 20px;
  .title {
    font-size: 18px;
    padding-bottom: 12px;
  }
  .q-item {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
